import React from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

const PageNotFound: React.FC = () => (
  <div>
    <p>404 - Page not found</p>
  </div>
);

export default withPrismicUnpublishedPreview(PageNotFound);
